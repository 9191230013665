// prefer default export if available
const preferDefault = m => (m && m.default) || m

exports.components = {
  "component---src-pages-404-jsx": () => import("./../../../src/pages/404.jsx" /* webpackChunkName: "component---src-pages-404-jsx" */),
  "component---src-pages-aboutus-index-jsx": () => import("./../../../src/pages/aboutus/index.jsx" /* webpackChunkName: "component---src-pages-aboutus-index-jsx" */),
  "component---src-pages-aboutus-liendoan-jsx": () => import("./../../../src/pages/aboutus/liendoan.jsx" /* webpackChunkName: "component---src-pages-aboutus-liendoan-jsx" */),
  "component---src-pages-aboutus-listofdoan-jsx": () => import("./../../../src/pages/aboutus/listofdoan.jsx" /* webpackChunkName: "component---src-pages-aboutus-listofdoan-jsx" */),
  "component---src-pages-adoration-campaign-jsx": () => import("./../../../src/pages/adoration-campaign.jsx" /* webpackChunkName: "component---src-pages-adoration-campaign-jsx" */),
  "component---src-pages-contactus-jsx": () => import("./../../../src/pages/contactus.jsx" /* webpackChunkName: "component---src-pages-contactus-jsx" */),
  "component---src-pages-events-jsx": () => import("./../../../src/pages/events.jsx" /* webpackChunkName: "component---src-pages-events-jsx" */),
  "component---src-pages-index-jsx": () => import("./../../../src/pages/index.jsx" /* webpackChunkName: "component---src-pages-index-jsx" */),
  "component---src-pages-maintenance-jsx": () => import("./../../../src/pages/maintenance.jsx" /* webpackChunkName: "component---src-pages-maintenance-jsx" */),
  "component---src-pages-mobile-app-privacy-jsx": () => import("./../../../src/pages/mobile-app-privacy.jsx" /* webpackChunkName: "component---src-pages-mobile-app-privacy-jsx" */),
  "component---src-pages-news-announcements-jsx": () => import("./../../../src/pages/news/announcements.jsx" /* webpackChunkName: "component---src-pages-news-announcements-jsx" */),
  "component---src-pages-news-broadcasts-jsx": () => import("./../../../src/pages/news/broadcasts.jsx" /* webpackChunkName: "component---src-pages-news-broadcasts-jsx" */),
  "component---src-pages-news-careerday-jsx": () => import("./../../../src/pages/news/careerday.jsx" /* webpackChunkName: "component---src-pages-news-careerday-jsx" */),
  "component---src-pages-news-index-jsx": () => import("./../../../src/pages/news/index.jsx" /* webpackChunkName: "component---src-pages-news-index-jsx" */),
  "component---src-pages-news-live-jsx": () => import("./../../../src/pages/news/live.jsx" /* webpackChunkName: "component---src-pages-news-live-jsx" */),
  "component---src-pages-news-tuyen-uy-communication-jsx": () => import("./../../../src/pages/news/tuyen-uy-communication.jsx" /* webpackChunkName: "component---src-pages-news-tuyen-uy-communication-jsx" */),
  "component---src-pages-page-2-js": () => import("./../../../src/pages/page-2.js" /* webpackChunkName: "component---src-pages-page-2-js" */),
  "component---src-pages-resources-bylaws-jsx": () => import("./../../../src/pages/resources/bylaws.jsx" /* webpackChunkName: "component---src-pages-resources-bylaws-jsx" */),
  "component---src-pages-resources-ceremonymanual-jsx": () => import("./../../../src/pages/resources/ceremonymanual.jsx" /* webpackChunkName: "component---src-pages-resources-ceremonymanual-jsx" */),
  "component---src-pages-resources-curricula-an-jsx": () => import("./../../../src/pages/resources/curricula/an.jsx" /* webpackChunkName: "component---src-pages-resources-curricula-an-jsx" */),
  "component---src-pages-resources-curricula-hs-jsx": () => import("./../../../src/pages/resources/curricula/hs.jsx" /* webpackChunkName: "component---src-pages-resources-curricula-hs-jsx" */),
  "component---src-pages-resources-curricula-index-jsx": () => import("./../../../src/pages/resources/curricula/index.jsx" /* webpackChunkName: "component---src-pages-resources-curricula-index-jsx" */),
  "component---src-pages-resources-curricula-ns-jsx": () => import("./../../../src/pages/resources/curricula/ns.jsx" /* webpackChunkName: "component---src-pages-resources-curricula-ns-jsx" */),
  "component---src-pages-resources-curricula-tn-jsx": () => import("./../../../src/pages/resources/curricula/tn.jsx" /* webpackChunkName: "component---src-pages-resources-curricula-tn-jsx" */),
  "component---src-pages-resources-forms-jsx": () => import("./../../../src/pages/resources/forms.jsx" /* webpackChunkName: "component---src-pages-resources-forms-jsx" */),
  "component---src-pages-resources-index-jsx": () => import("./../../../src/pages/resources/index.jsx" /* webpackChunkName: "component---src-pages-resources-index-jsx" */),
  "component---src-pages-resources-officialsongs-jsx": () => import("./../../../src/pages/resources/officialsongs.jsx" /* webpackChunkName: "component---src-pages-resources-officialsongs-jsx" */),
  "component---src-pages-resources-prayers-jsx": () => import("./../../../src/pages/resources/prayers.jsx" /* webpackChunkName: "component---src-pages-resources-prayers-jsx" */),
  "component---src-pages-resources-retreats-jsx": () => import("./../../../src/pages/resources/retreats.jsx" /* webpackChunkName: "component---src-pages-resources-retreats-jsx" */),
  "component---src-pages-resources-specializedskills-jsx": () => import("./../../../src/pages/resources/specializedskills.jsx" /* webpackChunkName: "component---src-pages-resources-specializedskills-jsx" */),
  "component---src-pages-resources-trainingregulations-jsx": () => import("./../../../src/pages/resources/trainingregulations.jsx" /* webpackChunkName: "component---src-pages-resources-trainingregulations-jsx" */),
  "component---src-pages-resources-trainingresources-jsx": () => import("./../../../src/pages/resources/trainingresources.jsx" /* webpackChunkName: "component---src-pages-resources-trainingresources-jsx" */),
  "component---src-pages-resources-webinars-jsx": () => import("./../../../src/pages/resources/webinars.jsx" /* webpackChunkName: "component---src-pages-resources-webinars-jsx" */),
  "component---src-pages-resources-weeklygospel-jsx": () => import("./../../../src/pages/resources/weeklygospel.jsx" /* webpackChunkName: "component---src-pages-resources-weeklygospel-jsx" */),
  "component---src-pages-stewardship-donate-jsx": () => import("./../../../src/pages/stewardship/donate.jsx" /* webpackChunkName: "component---src-pages-stewardship-donate-jsx" */),
  "component---src-pages-stewardship-membership-jsx": () => import("./../../../src/pages/stewardship/membership.jsx" /* webpackChunkName: "component---src-pages-stewardship-membership-jsx" */),
  "component---src-templates-page-jsx": () => import("./../../../src/templates/page.jsx" /* webpackChunkName: "component---src-templates-page-jsx" */),
  "component---src-templates-post-jsx": () => import("./../../../src/templates/post.jsx" /* webpackChunkName: "component---src-templates-post-jsx" */)
}

